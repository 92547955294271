import React from 'react';
import './GraphicsRequest.scss';
import { Redirect } from "react-router-dom";
import FirebaseContext from './../FirebaseContext';

import Button from './../components/Button';

import { authToNameEmail } from './../utils/FirebaseUtils'

class GraphicsRequest extends React.Component {


  state = {
    topic: "",
    type: "",
    description: "",
    details: "",
    deadline: "",
    user: null,

    done: false,
  }

  componentDidMount() {
    this.context.addCallback((user) => {
      this.setState({user: user});
    }, "auth");
  }

  sendRequest() {
    this.context.db.collection("graphics").add({
      topic: this.state.topic,
      type: this.state.type,
      description: this.state.description,
      details: this.state.details,
      from: this.state.user.displayName,
      timestamp: this.context.firebase.firestore.Timestamp.fromDate(new Date()),
      deadline: this.context.firebase.firestore.Timestamp.fromDate(new Date(this.state.deadline)),
    })
    .then((docRef) => {
      this.setState({done: true})
    })
    .catch((error) => {
      this.notify("Error: " + error.message);
    });
  }

  notify(message) {
    alert(message);
  }

  handleChange(event, property) {
    this.setState({[property]: event.target.value});
  }

  formValid() {
    return (this.state.topic && this.state.type && this.state.details && this.state.description && this.state.deadline)
  }

  render() {
    if (this.state.done) {
      return (<Redirect push to={"/info" }/>)
    }
    var signedIn = (this.state.user != null);
    return (
      <div className="App">
        <div className="App-form">
          <label>Email:</label>
          { signedIn ?
            (<input type="text" placeholder="" value={authToNameEmail(this.state.user)} readOnly/>) :
            (<Button primary onClick={() => {this.context.signIn();}}>Sign In</Button>)
          }
          <label>Topic:</label>
          <input type="text" placeholder="Election material" value={this.state.topic} onChange={(event) => {this.handleChange(event, "topic");}} />
          <label>Type:</label>
          <input type="text" placeholder="Poster A3+A5" value={this.state.type} onChange={(event) => {this.handleChange(event, "type");}} />
          <label>Deadline:</label>
          <input type="datetime-local" value={this.state.deadline} onChange={(event) => {this.handleChange(event, "deadline");}} />
          <label>Short Description:</label>
          <textarea placeholder="Common theme, idea, goal" value={this.state.description} onChange={(event) => {this.handleChange(event, "description");}} />
          <label>Details:</label>
          <textarea placeholder="Details, of individual posters, specific requests, etc" value={this.state.details} onChange={(event) => {this.handleChange(event, "details");}} style={{minHeight:"150px"}} />
          <Button primary onClick={() => {this.sendRequest();}} disabled={!signedIn || !this.formValid()}>Submit</Button>
        </div>
      </div>
    );
  }
}


GraphicsRequest.contextType = FirebaseContext;

export default GraphicsRequest;
