import React from 'react';
import './RegistrationDetails.scss';

import Button from '../../components/Button';
import AuthAwareComponent from '../../components/AuthAwareComponent';
import Input from '../../components/Input';

class RegistrationDetails extends AuthAwareComponent {

  constructor(props) {
    super(props);
    this.state.guid = "";
    this.state.dob = "";
    this.state.startyear = "";
    this.state.firstName = "";
    this.state.lastName = "";
  }

  render() {
    return (
      <div className="RegistrationDetails">
        Hey, now let's get some basic info about you, and make you a member!
        <Input placeholder="First Name" value={this.state.firstName} onChange={(e) => {this.setState({firstName: e.target.value})}}/>
        <Input placeholder="Last Name" value={this.state.lastName} onChange={(e) => {this.setState({lastName: e.target.value})}}/>
        <Input type="number" placeholder="GUID" value={this.state.guid} onChange={(e) => {this.setState({guid: e.target.value})}}/>
        <Input type="date" placeholder="Date of birth" value={this.state.dob} onChange={(e) => {this.setState({dob: e.target.value})}}/>
        <Input type="number" placeholder="University Start year" value={this.state.startyear} onChange={(e) => {this.setState({startyear: e.target.value})}}/>
        <Button primary onClick={() => {this.submitInfo()}}>Submit information</Button>
      </div>
    );
  }
  submitInfo() {
    this.context.db.collection('users').doc(this.state.user.user_id).set({
      guid: parseInt(this.state.guid),
      dob: this.state.dob,
      startyear: parseInt(this.state.startyear),
      firstName: this.state.firstName,
      lastName: this.state.lastName,
    }, {
      merge: true
    });
  }

}

export default RegistrationDetails;
