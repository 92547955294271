function authToName(auth) {
  return auth.displayName
}

function authToNameEmail(auth) {
  return auth.displayName + " (" + auth.email + ")"
}
const defaultContextData = {
  user: {waiting: true},
  userInfo: {waiting: true},
}

export {
  authToName,
  authToNameEmail,
  defaultContextData
}