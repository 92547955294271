import React from 'react';
import './GraphicsDashboard.scss';
import FirebaseContext from './../FirebaseContext';
import { authToName } from './../utils/FirebaseUtils';

import GraphicsItem from './../components/GraphicsItem';
import Button from './../components/Button';

class GraphicsDashboard extends React.Component {

  currentConnection = () => {};

  state = {
    user: null,
    data: [],
    notification: null
  }

  componentDidMount() {
    this.context.addCallback((user) => {
      this.setState({user: user});
      this.fetchBy("unclaimed");
    }, "auth");
  }

  fetchBy(filter) {
    this.currentConnection();
    switch (filter) {
      case "undone":
        this.currentConnection = this.context.db.collection("graphics").where("done", "==", false).orderBy("timestamp", "desc").onSnapshot((querySnapshot) => {this.receiveUpdate(querySnapshot)});
        break;
      case "done":
        this.currentConnection = this.context.db.collection("graphics").where("done", "==", true).orderBy("timestamp", "desc").onSnapshot((querySnapshot) => {this.receiveUpdate(querySnapshot)});
        break;
      default:
      case "unclaimed":
        this.currentConnection = this.context.db.collection("graphics").where("claimed", "==", null).orderBy("timestamp", "desc").onSnapshot((querySnapshot) => {this.receiveUpdate(querySnapshot)});
        break;
    }
  }

  receiveUpdate(snapshot) {
    this.setState({data: snapshot});
  }

  render() {
    var signedIn = (this.state.user != null);
    if (signedIn) {
      return (
        <div className="GraphicsDashboard">
          <div className="sorting">
            <Button onClick={() => {this.fetchBy("unclaimed")}}>Unclaimed</Button>
            <Button onClick={() => {this.fetchBy("undone")}}>In Progress</Button>
            <Button onClick={() => {this.fetchBy("done")}}>Done</Button>
          </div>
          <div className="content">
            {(() => {
              var result = []
              this.state.data.forEach((value) => {
                result.push(<GraphicsItem document={value.data()} docRef={value.id} key={value.id} yourName={authToName(this.state.user)}/>);
              });
              return result;
            })()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="GraphicsDashboard">
          You need to sign in
        </div>
      );
    }
  }
}


GraphicsDashboard.contextType = FirebaseContext;

export default GraphicsDashboard;
