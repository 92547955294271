import React from 'react';
import FirebaseContext from '../FirebaseContext';
import { defaultContextData } from '../utils/FirebaseUtils';

class AuthAwareComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = defaultContextData;
  }

  userCallback = (user) => {
    this.setState({
      user: user
    });
  }

  userInfoCallback = (userInfo) => {
    this.setState({
      userInfo: userInfo
    });
  }

  componentDidMount() {
    this.context.addCallback(this.userCallback, "user");
    this.context.addCallback(this.userInfoCallback, "userInfo");
  }

  componentWillUnmount() {
    this.context.removeCallback(this.userCallback, "user");
    this.context.removeCallback(this.userInfoCallback, "userInfo");
  }

}

AuthAwareComponent.contextType = FirebaseContext;

export default AuthAwareComponent;