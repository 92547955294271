import React from 'react';
import './NewHome.scss';

import AuthAwareComponent from './../components/AuthAwareComponent';
import Button from '../components/Button';
import InfoBox from '../components/InfoBox';

class NewHome extends AuthAwareComponent {

  redirectTo(location) {
    this.setState({redirect: location});
  }

  render() {
    if (this.state.userInfo?.membershipNumber) {
      return (
        <div className="NewHome">
          <InfoBox>This is your membership card. Add it to one of the supported wallets, or come back to this website when you need it.</InfoBox>
          <div className="Card">
            <div className="MemberIdHolder">
              { this.state.userInfo?.membershipNumber && <p className="MemberId">{this.state.userInfo.membershipNumber.toString().padStart(5, "0")}</p>}
            </div>
            <div className="GuidHolder">
              { this.state.userInfo?.guid && <p className="Guid">
                {this.state.userInfo.firstName.toUpperCase()} {this.state.userInfo.lastName.toUpperCase()}<br/>
                DOB: {this.state.userInfo.dob}<br/>
                GUID: {this.state.userInfo.guid}
              </p>}
            </div>
          </div>
          { this.state.userInfo?.googlePayLink && <Button className="gPay" onClick={() => { window.location = this.state.userInfo.googlePayLink}}/>}
        </div>
      );
    } else {
      return (
        <div className="NewHome">
          <InfoBox>
            Your membership card is being generated, please wait! If you think this is an error, please contact tech.con@qmunion.org.uk
          </InfoBox>
        </div>
      );
    }
  }
}

export default NewHome;
