import React from 'react';
import './Login.scss';

import Button from '../../components/Button';
import AuthAwareComponent from '../../components/AuthAwareComponent';
import Input from '../../components/Input';
import InfoBox from '../../components/InfoBox';

class Login extends AuthAwareComponent {

  constructor(props) {
    super(props);
    this.state.doRegister = true;
    this.state.email = "";
    this.state.password = "";
    this.state.password2 = "";
  }

  render() {
    return (
      <div className="Login">
        <InfoBox>
          Welcome to the QMU member website. <br/>
        </InfoBox>
        { this.state.doRegister ?
        <InfoBox>
          Use this form to sign up as a member. <br/>
          Alternatively click <span onClick={() => {this.setState({doRegister: !this.state.doRegister})}}>here</span> to log in. <br/>
        </InfoBox> :
        <InfoBox>
          Use this form to log in. <br/>
          Alternatively click <span onClick={() => {this.setState({doRegister: !this.state.doRegister})}}>here</span> to sign up as member. <br/>
        </InfoBox> }
        { this.state.doRegister ?
        <div className="EmailPassHolder">
          <Input placeholder="Email" type="email" value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}}/>
          <Input placeholder="Password" type="password" value={this.state.password} onChange={(e) => {this.setState({password: e.target.value})}}/>
          <Input placeholder="Repeat password" type="password" value={this.state.password2} onChange={(e) => {this.setState({password2: e.target.value})}}/>
          <Button primary onClick={() => {this.useEmail()}}>Sign up</Button>
          Or
          <Button className="GoogleButton" onClick={() => {this.context.signIn()}}></Button>
        </div> :
        <div className="EmailPassHolder">
          <Input placeholder="Email" type="email" value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}}/>
          <Input placeholder="Password" type="password" value={this.state.password} onChange={(e) => {this.setState({password: e.target.value})}}/>
          <Button primary onClick={() => {this.useEmail()}}>Log in</Button>
          Or
          <Button className="GoogleButton" onClick={() => {this.context.signIn()}}></Button>
        </div>
        }
      </div>
    );
  }

  useEmail() {
    if (this.state.doRegister) {
      if (this.state.password !== this.state.password2) {
        alert("Password do not match!");
        return;
      }
      this.context.auth.createUserWithEmailAndPassword(this.state.email, this.state.password).catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, errorMessage);
        alert(errorMessage);
      });
    } else {
      this.context.auth.signInWithEmailAndPassword(this.state.email, this.state.password).catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, errorMessage);
        alert(errorMessage);
      });
    }
  }
}

export default Login;
