import React from 'react';
import './ScreenWrapper.scss';

import AuthAwareComponent from './../components/AuthAwareComponent';
import Login from './registration/Login';
import RegistrationDetails from './registration/RegistrationDetails';

class ScreenWrapper extends AuthAwareComponent {

  render() {
    return (
      <div className="ScreenWrapper">
        <div className="ScreenWrapperContent">
          { this.renderCorrectState() }
        </div>
      </div>
    );
  }

  renderCorrectState() {
    if (this.state.user?.waiting || this.state.userInfo?.waiting) {
      return "LOADING"
    } else if (!this.state.user) {
      return <Login/>
    } else if (!this.state.userInfo?.guid || !this.state.userInfo?.dob || !this.state.userInfo?.startyear || !this.state.userInfo?.firstName || !this.state.userInfo?.lastName) {
      return <RegistrationDetails/>
    } else {
      return this.props.children;
    }
  }

}

export default ScreenWrapper;
