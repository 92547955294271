import React from 'react';
import './Button.scss';

class Button extends React.Component {

    generateClassName() {
        var result = "qmu-button "
        if (this.props.primary) {
            result += "primary "
        }
        if (this.props.className) {
        result += this.props.className;
        }
        return result
    }

  render() {
    return (
      <button
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        className={this.generateClassName()}>
          {this.props.children}
      </button>
    );
  }
}

export default Button;
