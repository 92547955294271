import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './index.scss';

import ScreenWrapper from './screens/ScreenWrapper'
import NewHome from './screens/NewHome';
import GraphicsRequest from './screens/GraphicsRequest';
import GraphicsDashboard from './screens/GraphicsDashboard';
import BoardReport from './screens/BoardReport';
import BigText from './screens/BigText';

import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import FirebaseConfig from './config/FirebaseConfig'
import FirebaseContext, { FirebaseContextObject } from './FirebaseContext';
var context = new FirebaseContextObject(firebase, FirebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={context}>
      <Router>
        <ScreenWrapper>
          <Switch>
            <Route path="/graphics_request">
              <GraphicsRequest/>
            </Route>
            <Route path="/graphics_dashboard">
              <GraphicsDashboard/>
            </Route>
            <Route path="/board_report">
              <BoardReport/>
            </Route>
            <Route path="/info">
              <BigText/>
            </Route>
            <Route path="/">
              <NewHome/>
            </Route>
          </Switch>
        </ScreenWrapper>
      </Router>
    </FirebaseContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
