import React from 'react';
import './InfoBox.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

class InfoBox extends React.Component {

  generateClassName() {
    var result = "InfoBox"
    if (this.props.primary) {
        result += " primary"
    }
    return result
  }

  render() {
    return (
      <div className="InfoBoxHolder">
      <div
        className={this.generateClassName()}>
          <FontAwesomeIcon icon={faInfoCircle}/>
          <div>
            {this.props.children}
          </div>
      </div>
      </div>
    );
  }
}

export default InfoBox;
