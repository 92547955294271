import React from 'react';
import './BoardReport.scss';
import BoardReportDetails from './BoardReportDetails'
import FirebaseContext from './../FirebaseContext';

class BoardReport extends React.Component {

  state = {
    user: null,
    data: {},
    selectedFolder: null,
  }

  componentDidMount() {
    this.context.addCallback((user) => {
      this.setState({user: user});
      this.context.db.collection("reports").orderBy("due", "desc").onSnapshot((querySnapshot) => {this.receiveUpdate(querySnapshot)});
    }, "auth");
  }

  receiveUpdate(snapshot) {
    var result = {};
    snapshot.forEach((value) => {
      result[value.id] = value.data();
    });
    this.setState({data: result});
  }

  render() {
    var signedIn = (this.state.user != null);
    if (signedIn) {
      return (
        <div className="BoardReport">
            <div className="DocList">
                {(() => {
                    var result = [];
                    for (const id in this.state.data) {
                        const value = this.state.data[id];
                        result.push(<div className={this.state.selectedFolder === id ? "ListItem clicked" : "ListItem"} key={id} onClick={() => {this.setState({selectedFolder: id})}}>{value.name}</div>);
                    }
                    return result;
                })()}
            </div>
            <div className="DocView">
                { this.state.selectedFolder ?
                    (<BoardReportDetails key={this.state.selectedFolder} folderId={this.state.selectedFolder} folderData={this.state.data[this.state.selectedFolder]}/>) :
                    (<p>Please select an event</p>)
                }
            </div>
        </div>
      );
    } else {
      return (
        <div className="BoardReport">
          You need to sign in
        </div>
      );
    }
  }
}


BoardReport.contextType = FirebaseContext;

export default BoardReport;
