import React from 'react';
import './Input.scss';

class Input extends React.Component {

    generateClassName() {
        var result = "qmu-input";
        if (this.props.narrow) {
            result += " narrow";
        }
        if (this.props.error) {
            result += " error";
        }
        return result;
    }

    render() {
        if (this.props.big) {
            return (
                <textarea
                    disabled={this.props.disabled}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    className={this.generateClassName()}
                />
            );
        } else {
            return (
                <input
                    type={this.props.type ? this.props.type : "text"}
                    disabled={this.props.disabled}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    className={this.generateClassName()}
                />
            );
        }
    }
}

export default Input;