import React from 'react';
import './BoardReportDetails.scss';
import FirebaseContext from './../FirebaseContext';

import BoardReportEntry from './../components/BoardReport/BoardReportEntry'
import Button from './../components/Button';
import Input from './../components/Input';

class BoardReportDetails extends React.Component {

    state = {
        user: {},
        data: [],

        addingReport: false,
        addingReportTitle: "",
        addingReportContent: ""
    }

    componentDidMount() {
        this.context.addCallback((user) => {
            this.setState({user: user});
        }, "auth");
        this.context.db.collection("reports/" + this.props.folderId + "/submissions").onSnapshot((querySnapshot) => {
            var result = [];
            querySnapshot.forEach((value) => {
                result.push(value);
            });
            this.setState({data: result});
        });
    }

    submitReport() {
        this.context.db.collection("reports/" + this.props.folderId + "/submissions").doc(this.state.user.uid).set({
            title: this.state.addingReportTitle,
            content: this.state.addingReportContent,
        }).then(() => {
            this.setState({
                addingReport: false,
                addingReportTitle: "",
                addingReportContent: ""
            });
        }).catch((e) => {
          console.log(e);
          alert(e.message);
        });
    }

    render() {
        var myReportSubmitted = false;
        return (
            <div className="BoardReportDetails">
                <h1>{this.props.folderData.name}</h1>
                {this.state.data.map((value) => {
                    if (value.id === this.state.user.uid) {
                        myReportSubmitted = true;
                    }
                    return <BoardReportEntry
                        key={value.id}
                        folderId={this.props.folderId}
                        entryId={value.id}
                        entryData={value.data()}
                    />
                })}
                {!this.state.addingReport && !myReportSubmitted && (
                    <Button onClick={() => {this.setState({addingReport: true})}}>Add report</Button>
                )}
                {this.state.addingReport && <div className="BoardReportEntry AddingReport">
                    <Input
                        value={this.state.addingReportTitle}
                        onChange={(e) => {this.setState({addingReportTitle: e.target.value})}}
                        placeholder={"Title"}
                    />
                    <Input
                        big
                        value={this.state.addingReportContent}
                        onChange={(e) => {this.setState({addingReportContent: e.target.value})}}
                        placeholder={"Your Board Report"}
                    />
                    <Button onClick={() => {this.submitReport()}}>Submit</Button>
                </div>}
            </div>
        );
    }
}

BoardReportDetails.contextType = FirebaseContext;

export default BoardReportDetails;
