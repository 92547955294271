import React from 'react';
import { defaultContextData } from './utils/FirebaseUtils';

const FirebaseContext = React.createContext(null);

export class FirebaseContextObject {

  firebase = null;
  db = null;
  auth = null;

  data = defaultContextData;
  userInfoListener = () => {};

  callbacks = {
    user: [],
    userInfo: [],
  }

  constructor(firebase, firebaseConfig) {
      this.firebase = firebase
      this.firebase.initializeApp(firebaseConfig);
      this.db = firebase.firestore();
      this.auth = firebase.auth();

      this.auth.onAuthStateChanged((user) => {
        this.userInfoListener();
        if (user) {
          user.getIdTokenResult().then((result) => {
            this.dataChange(result.claims, "user")
          });
          this.userInfoListener = this.db.collection('users').doc(user.uid).onSnapshot((doc) => {
            this.dataChange(doc.data(), "userInfo");
          });
        } else {
          this.dataChange(null, "user")
          this.dataChange(null, "userInfo")
        }
      });

      console.log("FirebaseContext initialized");
  }

  signIn() {
    // this.auth.signInWithPopup(new this.firebase.auth.OAuthProvider('microsoft.com'))
    this.auth.signInWithPopup(new this.firebase.auth.GoogleAuthProvider())
    .catch((error) => {
      alert("Error: " + error.message);
    });
  }

  dataChange(data, category) {
    console.log(category, data);
    this.data[category] = data;
    this.callbacks[category].forEach(callback => {
      try {
        callback(data);
      } catch (err) {
        this.removeCallback(callback, category);
      }
    });
  }

  addCallback(callback, category) {
    this.callbacks[category].push(callback);
    callback(this.data[category]);
  }
  removeCallback(callback, category) {
    var index = this.callbacks[category].indexOf(callback);
    this.callbacks[category].splice(index, 1);
  }
}

export default FirebaseContext;