import React from 'react';
import './BigText.scss';

class BigText extends React.Component {

  render() {
    return (
      <div className="BigText">
        {"Request has been successfully sent"}
      </div>
    );
  }
}

export default BigText;
