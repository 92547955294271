import React from 'react';
import './GraphicsItem.scss';
import FirebaseContext from './../FirebaseContext';

import Button from './Button'

class GraphicsItem extends React.Component {

  state = {
    expanded: false
  }

  getTitle(data) {
    return data.topic + " - " + data.from
  }

  claim() {
    this.context.db.collection("graphics").doc(this.props.docRef).update({
      claimed: this.props.yourName
    });
  }

  markAsDone() {
    this.context.db.collection("graphics").doc(this.props.docRef).update({
      done: true
    });
  }

  render() {
    var data = this.props.document;
    return (
      <div className="GraphicsItem">
        <p className="title">{this.getTitle(data)}</p>
        <p style={{fontWeight: "bold"}}>{data.claimed ? "Claimed: " + data.claimed : "Unclaimed!"}</p>
        <p>{data.type}</p>
        <p>{"Deadline: " + data.deadline.toDate().toLocaleString()}</p>
        <p>{data.description}</p>
        { this.state.expanded && <p>{data.details}</p> }
        <div className="buttons">
          {!this.state.expanded && <Button onClick={() => {this.setState({expanded: true})}}>Expand</Button>}
          {this.state.expanded && <Button onClick={() => {this.setState({expanded: false})}}>Close</Button>}
          {this.state.expanded && !data.claimed && <Button primary onClick={() => {this.claim()}}>Claim</Button>}
          {this.state.expanded && data.claimed === this.props.yourName && !data.done && <Button primary onClick={() => {this.markAsDone()}}>Mark as done</Button>}
        </div>
      </div>
    );
  }
}

GraphicsItem.contextType = FirebaseContext;

export default GraphicsItem;
