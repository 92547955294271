import React, {Fragment} from 'react';
import './BoardReportEntryQuestion.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import Button from './../Button';
import Input from './../Input';

class BoardReportEntryQuestion extends React.Component {

    state = {
        anwser: ""
    }

    constructor(props) {
        super();
        this.state.anwser = props.questionData.anwser;
    }

    render() {
        return (
            <div className="BoardReportEntryQuestion">
                {this.props.questionData.question}
                <br/>
                <FontAwesomeIcon icon={faAngleDoubleRight}/>
                {this.props.anwserEditable ? (
                    <Fragment>
                        <Input
                            narrow
                            error={this.props.questionData.anwser !== this.state.anwser}
                            value={this.state.anwser}
                            onChange={(e) => {this.setState({anwser: e.target.value})}}
                        />
                        <Button
                            onClick={() => {this.props.anwserEditableAction(this.state.anwser)}}
                        >Change anwser</Button>
                    </Fragment>
                ) : (
                    this.props.questionData.anwser
                )}
            </div>
        );
    }
}

export default BoardReportEntryQuestion;