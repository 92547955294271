import React, {Fragment} from 'react';
import './BoardReportEntry.scss';
import FirebaseContext from './../../FirebaseContext';

import BoardReportEntryQuestion from './BoardReportEntryQuestion';
import Button from './../Button';
import Input from './../Input';

class BoardReportEntry extends React.Component {

    state = {
        user: {},
        questions: [],

        addingQuestion: false,
        addingQuestionContent: ""
    }

    componentDidMount() {
        this.context.addCallback((user) => {
          this.setState({user: user});
        }, "auth");
        this.context.db.collection("reports/" + this.props.folderId + "/questions").where("for", "==", this.props.entryId).onSnapshot((querySnapshot) => {
            var result = [];
            querySnapshot.forEach((value) => {
                result.push(value);
            });
            this.setState({questions: result});
        });
    }

    submitQuestion() {
        this.context.db.collection("reports/" + this.props.folderId + "/questions").add({
            for: this.props.entryId,
            question: this.state.addingQuestionContent
        }).then(() => {
            this.setState({
                addingQuestion: false,
                addingQuestionContent: ""
            });
        }).catch((e) => {
            console.log(e);
            alert(e.message);
        })
    }

    submitAnwser(questionId, anwserText) {
        this.context.db.collection("reports/" + this.props.folderId + "/questions").doc(questionId).update({
            anwser: anwserText
        }).then(() => {
        }).catch((e) => {
            console.log(e);
            alert(e.message);
        })
    }

    render() {
        const myEntry = this.state.user.uid === this.props.entryId;
        return (
            <div className="BoardReportEntry">
                <h2>{this.props.entryData.title}</h2>
                <p style={{whiteSpace: "pre-line"}}>{this.props.entryData.content}</p>
                <div className="Questions">
                    {this.state.questions.map((value) => {
                        return <BoardReportEntryQuestion
                            key={value.id}
                            anwserEditable={myEntry}
                            anwserEditableAction={(anwserText) => {this.submitAnwser(value.id, anwserText)}}
                            questionData={value.data()}
                        />
                    })}
                    {!myEntry && <div className="BoardReportEntryQuestion">
                        { !this.state.addingQuestion && <Button onClick={() => {this.setState({addingQuestion: true})}}>Submit a question</Button>}
                        { this.state.addingQuestion && <Fragment>
                            <Input
                                narrow
                                value={this.state.addingQuestionContent}
                                onChange={(e) => {this.setState({addingQuestionContent: e.target.value})}}
                                placeholder={"Enter your question"}
                            />
                            <Button onClick={() => {this.submitQuestion()}}>Submit</Button>
                        </Fragment>}
                    </div>}
                </div>
            </div>
        );
    }
}

BoardReportEntry.contextType = FirebaseContext;

export default BoardReportEntry;